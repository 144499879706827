import React, { useState } from "react";
import styled from "styled-components";
import { mainWhite, mainBlack, larsseit, screen } from "../common/variables";
import Slider from "react-slick";

const Wrapper = styled.section`
  background-color: ${mainBlack};
  color: ${mainWhite};
  font-family: ${larsseit};
  padding: 44px 26px;
  @media ${screen.xsmall} {
    padding: 60px 60px;
  }
  @media ${screen.small} {
    padding: 90px 80px;
  }
  @media ${screen.medium} {
    padding: 90px 11vw;
  }
  @media ${screen.xlarge} {
    padding: 120px 11vw;
  }

  .slick-slider {
    .slick-list {
      .slick-track {
        .slick-slide {
          pointer-events: none;

          &__item {
            .testimonial-quotes {
              display: block;
              font-size: 6rem;
              line-height: 1;
              margin-bottom: -40px;
              @media ${screen.xsmall} {
                font-size: 6.6rem;
                margin-bottom: -40px;
              }
              @media ${screen.small} {
                margin-bottom: -30px;
                font-size: 6.6rem;
              }
              @media ${screen.medium} {
                margin-bottom: -66px;
                font-size: 11rem;
              }
            }

            .testimonial-heading {
              h2 {
                line-height: 1.2;
                font-weight: 700;
                font-size: 1.75rem;
                margin-bottom: 10px;
                @media ${screen.xsmall} {
                  margin-bottom: 13px;
                }
                @media ${screen.small} {
                  font-size: 2.5rem;
                }
                @media ${screen.medium} {
                  font-size: clamp(2.5rem, calc(0.8rem + 2vw), 3.21rem);
                  margin-bottom: 15px;
                }
              }
            }

            .testimonial-msg {
              max-width: 1300px;
              p {
                font-weight: 100;
                line-height: 1.35;
                font-size: 1.31rem;
                @media ${screen.xsmall} {
                  font-size: 1.8rem;
                }
                @media ${screen.small} {
                  font-size: 2.2rem;
                }
                @media ${screen.medium} {
                  font-size: 2.5rem;
                }
              }
            }

            .testimonial-fullname {
              margin-top: 26px;
              @media ${screen.xsmall} {
                font-size: 0.88rem;
                margin-bottom: 4px;
                margin-top: 30px;
              }
              @media ${screen.small} {
                margin-top: 60px;
                font-size: 1.1rem;
              }
              @media ${screen.medium} {
                font-size: clamp(1.2rem, calc(0.3rem + 1vw), 1.53rem);
                margin-top: 65px;
              }
            }

            .testimonial-company {
              @media ${screen.xsmall} {
                font-size: 0.88rem;
              }
              @media ${screen.small} {
                font-size: 1.1rem;
              }
              @media ${screen.medium} {
                font-size: clamp(1.2rem, calc(0.3rem + 1vw), 1.53rem);
                margin-bottom: 65px;
              }
            }
          }
        }
      }
    }
  }

  .dots {
    display: flex;
    margin-top: 50px;
    @media ${screen.small} {
      margin-top: 80px;
    }
    @media ${screen.medium} {
      margin-top: 30px;
    }

    &__dot {
      height: 4px;
      width: 49px;
      background-color: #707070;
      display: block;
      margin-right: 13px;
      cursor: pointer;
      @media ${screen.medium} {
        width: 65px;
        width: calc(26px + 2vw);
        height: 6px;
        margin-right: 22px;
      }

      &--active {
        background-color: ${mainWhite};
      }
    }
  }
`;

const CarouselTestimonials = ({ testimonial_list }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    // adaptiveHeight: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipe: false,
    fade: true,
    speed: 750,
    arrows: false,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    easing: `linear`,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  let changeSlide;

  return (
    <Wrapper>
      <Slider
        ref={slider => (changeSlide = slider && slider.slickGoTo)}
        {...settings}
      >
        {testimonial_list.map((item, i) => (
          <div
            className="slick-slide__item"
            data-sal="slide-up"
            data-sal-duration="500"
            key={item.full_name}
          >
            <span className="testimonial-quotes">“</span>
            <div
              className="testimonial-heading"
              dangerouslySetInnerHTML={{ __html: item.heading.html }}
            />
            <div
              className="testimonial-msg"
              dangerouslySetInnerHTML={{ __html: item.message.html }}
            />
            <p className="testimonial-fullname">{item.full_name}</p>
            <p className="testimonial-company">{item.company}</p>
          </div>
        ))}
      </Slider>
      <div className="dots">
        {testimonial_list.map((item, i) => (
          <span
            onClick={() => changeSlide(i)}
            className={
              currentSlide === i ? "dots__dot dots__dot--active" : "dots__dot"
            }
            key={i}
          ></span>
        ))}
      </div>
    </Wrapper>
  );
};

export default CarouselTestimonials;
