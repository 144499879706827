import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import {
  larsseit,
  graphik,
  mainWhite,
  mainBlack,
  mainBlue,
  lightGray,
  transHover,
  screen,
} from "../common/variables";
import Arrow from "../../images/svg/arrow.svg";
import LabeledArrow from "../../components/labeled-arrow";
import Loader from "../../images/svg/loader.svg";
import emailjs from "emailjs-com";
import {
  AiOutlineExclamationCircle,
  AiOutlineCheckCircle,
} from "react-icons/ai";

const enterField = keyframes`
from {
  opacity: 0;
}

to  {
  opacity: 1;
}
`;

const Wrapper = styled.section`
  background-color: ${lightGray};
  position: relative;
  padding: 26px;
  @media ${screen.xsmall} {
    padding: 0;
  }

  .inner {
    background-color: ${mainBlue};
    position: relative;
    padding: 0 0 26px 0;
    @media ${screen.xsmall} {
      padding: 50px 0 65px 0;
    }
    @media ${screen.small} {
      padding: 90px 0 65px 0;
    }
    @media ${screen.xlarge} {
      padding: 120px 0;
    }

    .form-heading {
      color: ${mainWhite};
      font-family: ${larsseit};
      line-height: 1.17;
      font-size: 2.3rem;
      margin: 0 auto 26px auto;
      padding: 26px 26px 0 26px;
      max-width: 700px;
      @media ${screen.xsmall} {
        margin: 0 auto 50px auto;
        text-align: center;
        padding: 0;
      }
      @media ${screen.small} {
        font-size: 2.9rem;
      }
      @media ${screen.medium} {
        font-size: 3rem;
      }
    }

    #form {
      p {
        position: relative;
        transition: ${transHover};
        animation: ${enterField} 0.7s ease-out;
        width: 100%;
        max-width: 960px;
        margin: 0 auto;
        padding: 0 26px;
        @media ${screen.xsmall} {
          max-width: 600px;
          padding: 0;
        }
        @media ${screen.small} {
          max-width: 690px;
        }
        @media ${screen.medium} {
          max-width: 960px;
        }

        input {
          background-color: ${mainBlue};
          border-radius: 0;
          border: 0;
          border-bottom: 2px solid ${mainWhite};
          color: ${mainWhite};
          display: block;
          font-family: ${graphik};
          font-weight: 100;
          font-size: 1.1rem;
          outline: none;
          padding: 9px 0;
          margin-bottom: 22px;
          width: calc(100% - 60px);
          @media ${screen.xsmall} {
            padding: 10px 8px;
            width: calc(100% - 80px);
          }
          @media ${screen.small} {
            font-size: 1.5rem;
            padding: 10px 8px;
            width: calc(100% - 100px);
          }
          @media ${screen.medium} {
            font-size: clamp(1.5rem, calc(0.8rem + 1vw), 1.98rem);
            padding: 14px 8px;
            width: calc(100% - 110px);
            border-bottom: 3px solid ${mainWhite};
          }
          @media ${screen.large} {
            width: calc(100% - 120px);
          }
          @media ${screen.xlarge} {
            padding: 22px 10px;
            width: calc(100% - 140px);
          }

          ::placeholder {
            color: ${mainWhite};
            opacity: 0.5;
          }
        }

        textarea {
          background-color: ${mainBlue};
          border-radius: 0;
          border: 2px solid ${mainWhite};
          color: ${mainWhite};
          display: block;
          width: 100%;
          font-family: ${graphik};
          font-weight: 100;
          outline: none;
          padding: 9px;
          height: 90px;
          margin-top: 44px;
          resize: none;
          font-size: 1.1rem;
          @media ${screen.xsmall} {
            height: 152px;
            padding: 10px 8px;
          }
          @media ${screen.small} {
            font-size: 1.5rem;
            padding: 10px 8px;
          }
          @media ${screen.medium} {
            padding: 22px 10px;
            font-size: clamp(1.5rem, calc(0.8rem + 1vw), 1.98rem);
            height: 200px;
          }

          ::placeholder {
            color: ${mainWhite};
            opacity: 0.5;
          }
        }

        svg {
          width: 33px;
          display: block;
          position: absolute;
          top: calc(50% + 18px);
          transform: translateY(-50%);
          right: 26px;
          cursor: pointer;
          @media ${screen.xsmall} {
            width: 42px;
            right: 6px;
            top: calc(50% + 20px);
          }
          @media ${screen.small} {
            width: 60px;
            top: calc(50% + 23px);
          }
          @media ${screen.medium} {
            position: absolute;
            top: calc(50% + 30px);
          }
          @media ${screen.large} {
            width: 70px;
          }
          @media ${screen.xlarge} {
            top: calc(50% + 40px);
            width: 80px;
          }

          .arrow-line {
            stroke: ${mainWhite};
            transition: ${transHover};
          }

          @media ${screen.withCursor} {
            &:hover .arrow-line {
              stroke: ${mainBlack};
            }
          }
        }
      }

      #submit-btn {
        display: flex;
        justify-content: flex-end;
        padding: 0 26px;
        margin: 22px 0;
        @media ${screen.xsmall} {
          max-width: 600px;
          margin: 22px auto 22px auto;
          padding: 0;
        }
        @media ${screen.small} {
          max-width: 690px;
        }
        @media ${screen.medium} {
          max-width: 960px;
        }

        @media ${screen.withCursor} {
          &:hover .label {
            color: ${mainBlack};
          }

          &:hover svg .arrow-line {
            stroke: ${mainBlack};
          }
        }
      }

      #hidden-submit-btn {
        display: none;
      }
    }

    #error-feedback,
    #success-feedback {
      width: calc(100% - 52px);
      margin: 0 auto;
      font-size: 1rem;
      @media ${screen.xsmall} {
        max-width: 600px;
        width: 100%;
        font-size: 1.05rem;
      }
      @media ${screen.small} {
        max-width: 690px;
      }
      @media ${screen.medium} {
        max-width: 960px;
      }

      .feedback-flex {
        color: ${mainBlack};
        display: block;
        font-family: ${larsseit};
        background: ${mainWhite};
        display: flex;
        padding: 6px 9px 4px 9px;
        @media ${screen.xsmall} {
          display: table;
          padding: 6px 10px 4px 10px;
        }

        svg {
          vertical-align: middle;
          margin: 2px 6px 0 0;
          font-size: 1.15rem;
          min-width: 22px;
          @media ${screen.xsmall} {
            font-size: 1.2rem;
            margin: -3px 5px 0 0;
            min-width: initial;
          }
        }
      }
    }

    &__arrow {
      width: 0;
      height: 0;
      border-left: 40px solid transparent;
      border-right: 40px solid transparent;
      border-top: 34px solid ${mainBlue};
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -30px;
    }
  }

  #sending-loader {
    background: rgba(255, 255, 255, 0.92);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 80px;
      @media ${screen.small} {
        width: 100px;
      }
    }
  }
`;

// client side validations
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const nameRegex = /^[a-zA-Z ]+$/i;

const ContactForm = ({ headingText }) => {
  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setUserInput({
      ...userInput,
      [e.target.name]: e.target.value,
    });
  };

  // dyanmic text feedback based on condition
  const [textFeedback, setTextFeedback] = useState(null);

  // to hide/show error feedback
  const [errorFeedback, setErrorFeedback] = useState(false);

  // to hide/show success feedback
  const [successFeedback, setSuccessFeedback] = useState(false);

  // to show a loading state
  const [isSending, setIsSending] = useState(false);

  // to hide/show email field
  const [isEmailActive, setEmailActive] = useState(false);

  // to hide/show message field
  const [isMsgActive, setMsgActive] = useState(false);

  // on form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();

    if (userInput.message.length) {
      handleInputSuccess();
      setErrorFeedback(false);
    } else {
      setErrorFeedback(true);
      setTextFeedback("Oops! You forgot to say something.");
    }
  };

  // name field handler
  const handleName = (e) => {
    e && e.preventDefault();
    if (nameRegex.test(userInput.name)) {
      setEmailActive(true);
      setErrorFeedback(false);
    } else {
      setErrorFeedback(true);
      setTextFeedback("Please enter your name.");
    }
  };

  // email field handler
  const handleEmail = (e) => {
    e && e.preventDefault();
    if (emailRegex.test(userInput.email)) {
      setMsgActive(true);
      setErrorFeedback(false);
    } else {
      setErrorFeedback(true);
      setTextFeedback("Please enter a valid email.");
    }
  };

  // if validation is all valid
  const handleInputSuccess = () => {
    setIsSending(true);

    emailjs
      .send(
        "Gmail",
        "template_zqvbi2i",
        userInput,
        "user_IDhsTDBUmk9S6AL8GtjPO"
      )
      .then((response) => {
        setUserInput({
          name: "",
          email: "",
          message: "",
        });

        setIsSending(false);
        setEmailActive(false);
        setMsgActive(false);
        setSuccessFeedback(true);
        setTextFeedback(null);
      })
      .catch((e) => {
        console.log("error", e);
        setUserInput({
          name: "",
          email: "",
          message: "",
        });
        alert(
          "Sorry we couldn't process this at this time. Please try again later or contact us by email."
        );
        setIsSending(false);
        setEmailActive(false);
        setMsgActive(false);
        setTextFeedback(null);
      });
  };

  // to reset feedback
  const handleFocus = () => {
    setErrorFeedback(false);
    setSuccessFeedback(false);
  };

  return (
    <Wrapper>
      <div className="inner">
        <h2
          className="form-heading"
          data-sal="slide-up"
          data-sal-duration="500"
        >
          {headingText ||
            "Get in touch to see how we can help grow your business."}
        </h2>

        <form
          id="form"
          onFocus={handleFocus}
          onSubmit={(e) => {
            e.preventDefault();

            if (e.currentTarget[0]) {
              handleName();
            }

            if (e.currentTarget[2]) {
              handleEmail();
            }
          }}
          data-sal="slide-up"
          data-sal-duration="500"
        >
          <p className="name-input">
            <input
              onChange={handleChange}
              type="text"
              placeholder="Your name please"
              name="name"
              value={userInput.name}
            />
            <span onClick={handleName}>
              <Arrow />
            </span>
          </p>
          {isEmailActive && (
            <p className="email-input">
              <input
                onChange={handleChange}
                type="text"
                placeholder="What's your email?"
                name="email"
                value={userInput.email}
              />
              <span onClick={handleEmail}>
                <Arrow />
              </span>
            </p>
          )}
          {isMsgActive && (
            <p className="msg-input">
              <textarea
                onChange={handleChange}
                placeholder="Tell us how can we help"
                name="message"
                value={userInput.message}
              ></textarea>
            </p>
          )}

          {isMsgActive && (
            <span id="submit-btn" onClick={handleSubmit}>
              <LabeledArrow label="SUBMIT" btn="true" />
            </span>
          )}
          <button type="submit" id="hidden-submit-btn">
            submit
          </button>
        </form>

        {errorFeedback && (
          <p id="error-feedback">
            {
              <span className="feedback-flex">
                <AiOutlineExclamationCircle /> <span>{textFeedback}</span>
              </span>
            }
          </p>
        )}
        {successFeedback && (
          <p id="success-feedback">
            {
              <span className="feedback-flex">
                <AiOutlineCheckCircle />
                <span>
                  Thank you for your message! Someone from our team will be in
                  touch shortly.
                </span>
              </span>
            }
          </p>
        )}

        {!isSending && <span className="inner__arrow"></span>}
      </div>

      {isSending && (
        <div id="sending-loader">
          <Loader />
        </div>
      )}
    </Wrapper>
  );
};

export default ContactForm;
